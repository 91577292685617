import React from 'react';
import Layout from '../components/layout';
import { Container, Icon } from '@material-ui/core';
import InstagramIcon from '@material-ui/icons/Instagram';

const Contact = () => {
    return (
        <Layout>
            <Container style={{textAlign:'center', padding:'15px'}}>
                <h1>The Vegan Dive LLC</h1>
                <br></br>
                <a href="mailto:thevegandivellc@gmail.com">Order by sending us an email!</a>
                <br></br>
                <br></br>
                <h2>For more information, find us on Instagram!</h2>
                <br></br>
                <a href="https://www.instagram.com/thevegandive/"><InstagramIcon style={{fontSize:'64px'}}/></a> 
            </Container>
        </Layout>
    );
}

export default Contact;
